<template>
  <div>
    <b-row>
      <b-col md="4">
        <b-form-group rules="required">
          <label class="mr-1">{{ $t("labels.datefrom") }}</label>

          <flat-pickr
            v-model="filterSelect.dateFrom"
            class="form-control"
            :config="{
              altInput: true,
              altFormat: `d-m-Y H${
                filterSelect.api.value == 'v2/report/players-by-brands' ? '' : ':i'
              }`,
              enableTime: true,
              time_24hr: true,
            }"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group rules="required">
          <label class="mr-1">{{ $t("labels.dateto") }}</label>
          <flat-pickr
            v-model="filterSelect.dateTo"
            class="form-control"
            :config="{
              altInput: true,
              altFormat: `d-m-Y H${
                filterSelect.api.value == 'v2/report/players-by-brands' ? '' : ':i'
              }`,
              enableTime: true,
              time_24hr: true,
            }"
          />
          <!-- <label class="mr-1 text-danger" v-if="filterSelect.dateFrom"
              >Rango maximo de 30 dias
            </label> -->
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group>
          <label class="mr-1 text-lg" for="customRadioInline1"
            >{{ $t("labels.currency") }}
          </label>
          <v-select
            v-model="filterSelect.currency"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :clearable="false"
            :options="currencyOptions"
            :reduce="(currency) => currency.value"
            label="name"
            placeholder="Selecciona una Moneda"
            :close-on-select="false"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group>
          <label class="mr-1" for="customRadioInline1">WL </label>
          <v-select
            v-model="filterSelect.WL"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="wlOptions"
            label="name"
            :reduce="(wl) => wl._id"
            placeholder="Select WL"
            value=""
          />
        </b-form-group>
      </b-col>
      <b-col v-if="typeUserLoged == 'Root'" md="4">
        <b-form-group>
          <label class="mr-1 text-lg" for="customRadioInline1"
            >Clientes
            <span class="text-danger">*</span>
          </label>
          <v-select
            v-model="filterSelect.customer"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="customerOptions"
            label="name"
            @option:selected="selectCustomer"
            :reduce="(customer) => customer._id"
            placeholder="Seleccione un cliente"
            value=""
            multiple
            :loading="loadingDataCustomer"
            :close-on-select="false"
          >
            <template #option="{ name }">
              <feather-icon icon="UserIcon" size="16" class="align-middle mr-50" />
              <span> {{ name }}</span>
            </template>

            <!-- buttom select all  -->
            <template #list-header>
              <div class="d-flex justify-content-center my-1">
                <b-button
                  size="sm"
                  @click="filterSelect.customer = customerOptions.map((c) => c._id)"
                  class="text-nowrap"
                  variant="primary"
                  v-if="
                    customerOptions.length > 1 &&
                    filterSelect.customer.length !== customerOptions.length
                  "
                >
                  <feather-icon icon="CheckSquareIcon" class="mr-50" />
                  <span class="text-nowrap">Select All</span>
                </b-button>
                <b-button
                  size="sm"
                  @click="filterSelect.customer = []"
                  class="text-nowrap"
                  variant="primary"
                  v-if="filterSelect.customer.length > 0"
                >
                  <feather-icon icon="SquareIcon" class="mr-50" />
                  <span class="text-nowrap">Unselect All</span>
                </b-button>
              </div>
            </template>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group>
          <label class="mr-1">Productos</label>
          <v-select
            v-model="filterSelect.products"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="productsOptions"
            label="name"
            :reduce="(product) => product._id"
            placeholder="Select products"
            multiple
            :close-on-select="false"
          >
            <template #list-header>
              <div class="d-flex justify-content-center my-1">
                <b-button
                  size="sm"
                  @click="selectProductsFilter('all')"
                  class="text-nowrap"
                  variant="primary"
                  v-if="showBotomSelectAllProducts"
                >
                  <feather-icon icon="CheckSquareIcon" class="mr-50" />
                  <span class="text-nowrap">Select All</span>
                </b-button>
                <b-button
                  size="sm"
                  @click="selectProductsFilter('none')"
                  class="text-nowrap"
                  variant="primary"
                  v-if="!showBotomSelectAllProducts && productsOptions.length > 1"
                >
                  <feather-icon icon="SquareIcon" class="mr-50" />
                  <span class="text-nowrap">Unselect All</span>
                </b-button>
              </div>
            </template>
          </v-select>
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group>
          <label class="mr-1">Api</label>
          <v-select
            v-model="filterSelect.api"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="apisOptions"
            placeholder="Seleccione la moneda"
            value="api"
            @input="filterSelect.products = []"
          />
        </b-form-group>
      </b-col>
      <b-col 
      v-if="isFilterByPlayer"
      class="d-flex align-items-center justify-content-end">
        <b-col md="6">
          <b-form-group>
            <label class="mr-1 text-lg" for="customRadioInline1">Jugador </label>
            <v-select
              v-model="filterSelect.playerName"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="playerNameOptions"
              :clearable="false"
              label="username"
              :reduce="(player) => player._id"
              @search="
                (search, loading) => {
                  this.searchPlayer(search, loading);
                }
              "
              placeholder="Selecciona un Jugador"
            >
              <template #no-options="{ search, searching, loading }">
                Escribe para buscar un jugador
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-col>
    </b-row>

    <b-row class="mb-1">
      <b-col class="d-flex align-items-center justify-content-end">
        <b-button
          :disabled="loadingDataReport"
          @click="getReport"
          class="text-nowrap"
          variant="primary"
        >
          <b-spinner small v-if="loadingDataReport"></b-spinner>
          <feather-icon v-if="!loadingDataReport" icon="SearchIcon" class="mr-50" />
          <span v-if="!loadingDataReport" class="text-nowrap">{{
            $t("labels.search")
          }}</span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BOverlay,
  BPagination,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BInputGroup,
  BCollapse,
  VBToggle,
  VBModal,
  BCardText,
  BTableSimple,
  BThead,
  BTfoot,
  BTh,
  BTr,
  BTd,
  BSpinner,
  BProgress,
} from "bootstrap-vue";

import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import store from "@/store";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BPagination,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BInputGroup,
    BCollapse,
    VBToggle,
    VBModal,
    BCardText,
    BTableSimple,
    BThead,
    BTfoot,
    BTh,
    BTr,
    BTd,
    BSpinner,
    BProgress,

    // custom components
    vSelect,
    flatPickr,
    ToastificationContent,
  },
  props: {
    loadingDataReport: Boolean,
    isFilterByPlayer: { type: Boolean, default: true },
    apisOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loadingDataCustomer: false,
      loadingDataProducts: false,
      filterSelect: {
        dateFrom: new Date(),
        dateTo: new Date(),
        currency: "all",
        WL: "",
        customer: [],
        // api select second for apisOptions
        api: this.apisOptions[1],
        products: [],
        product: "",
        playerName: "",
      },

      customerOptions: [],
      currencyOptions: [
        { name: "Todas", value: "all" },
        { name: "ARS", value: "ARS" },
        { name: "BRL", value: "BRL" },
        { name: "CLP", value: "CLP" },
        { name: "COP", value: "COP" },
        { name: "EUR", value: "EUR" },
        { name: "MXN", value: "MXN" },
        { name: "MYR", value: "MYR" },
        { name: "PEN", value: "PEN" },
        { name: "PYG", value: "PYG" },
        { name: "USD", value: "USD" },
        { name: "UYU", value: "UYU" },
        { name: "VES", value: "VES" },
        { name: "XAF", value: "XAF" },
      ],
      wlOptions: [],
      productsOptions: [],
      playerNameOptions: [{ username: "Todos", _id: "all" }],

    };
  },
  watch: {
  "filterSelect.customer": {
    handler(value) {
      // validar si viene un array vacio 
      if (value.length === 0) {
        this.productsOptions = [];
        this.filterSelect.products = [];
        return;
      }

      const productsSet = new Set();

      // acumular todos los productos sin duplicados
      value.forEach((customer) => {
        const customerProducts =
          this.customerOptions?.find((c) => c._id === customer)?.products || [];
        customerProducts.forEach((product) => productsSet.add(product._id));
      });

      // transformar el set a un array de productos únicos
      this.productsOptions = [...productsSet].map((id) =>
        this.customerOptions.flatMap((c) => c.products).find((p) => p._id === id)
      );

      // buscar si los productos ya estan seleccionados en el filtro 
      this.filterSelect.products = this.filterSelect.products.filter((product) =>
        this.productsOptions.find((p) => p._id === product)
      );
    },
    deep: true,
  },
},

  computed: {
    typeUserLoged() {
      return this.$store.getters["usersModule/typeUser"];
    },
    showBotomSelectAllProducts() {
      return (
        this.productsOptions?.length > 1 &&
        this.filterSelect.products?.length !== this.productsOptions?.length
      );
    },
  },
  methods: {
    inputSelect() {
      this.$emit("inputSelect", this.filterSelect);
    },
    async getWlOptions() {
      try {
        this.loadingWlOptions = true;
        const customerSelected = this.customerOptions.find(
          (customer) => customer?._id == this.filterSelect.customer
        );
        const res = await this.$store.dispatch("reports/getWlOptions", {
          customerId: customerSelected?.customerId,
        });
        this.wlOptions = res.data;
        this.filterSelect.WL = "";
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingWlOptions = false;
      }
    },
    async getCustomer() {
      try {
        this.loadingDataCustomer = true;
        if (this.typeUserLoged !== "Root") return;
        const res = await this.$store.dispatch("customerModule/getCustomers", {
          paginate: {
            page: 1,
            perPage: 200,
            sortDesc: true,
          },
        });
        this.customerOptions = res.data.customers.map((customer) => ({
          name: customer.name,
          _id: customer._id,
          customerId: customer.customerId,
          products: customer.products.map((product) => ({
            name: product.name,
            _id: product._id,
          })),
        }));
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingDataCustomer = false;
      }
    },
    async getProducts() {
      try {
        this.loadingDataProducts = true;
        if (this.typeUserLoged !== "Root") {
          this.filterSelect.customer = [this.userLoged.customerId];
          const res = await this.$store.dispatch("reports/getProducts");
          this.productsOptions = res.data;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingDataProducts = false;
      }
    },

    selectProductsFilter(type) {
      if (type === "all") {
        this.filterSelect.products = this.productsOptions.map((product) => product._id);
      } else {
        this.filterSelect.products = [];
      }
    },
    selectCustomer(customers) {
      this.filterSelect.playerName = "all";
      this.getWlOptions();
    },

    async searchPlayer(search, loading) {
      if (!search) return;

      try {
        clearTimeout(this.timeoutSearchPlayer);
        this.timeoutSearchPlayer = setTimeout(async () => {
          loading(true);
          const customerSelect = this.customerOptions
            ? this.customerOptions.find((item) => item._id == this.filterSelect.customer[0])
            : null;
          const customerId = customerSelect ? customerSelect.customerId : null;
          const payload = {
            customerId: customerId || this.userLoged.customerId,
            player: search,
          };
          const res = await this.$store.dispatch("reports/fetchUsersPlayers", payload);
          this.playerNameOptions = res.data.users;
          this.playerNameOptions.unshift({ username: "Todos", _id: "all" });
        }, 500);
      } catch (error) {
      } finally {
        setTimeout(() => {
          loading(false);
        }, 900);
      }
    },

    getReport() {
        this.filterSelect.product = this.filterSelect.products
        this.$emit("getReportEvent", this.filterSelect);
    },
  },
  created() {
    this.getCustomer();
    this.getProducts();

    this.filterSelect.dateFrom = moment()
      .subtract(29, "days")
      .format("YYYY-MM-DD 00:00");
    this.filterSelect.dateTo = moment().format("YYYY-MM-DD 23:59");
    this.minDate = moment().subtract(29, "days").format("YYYY-MM-DD 00:00");
    this.maxDate = moment().format("YYYY-MM-DD 23:59");
  },
};
</script>

<style lang="scss" scoped></style>
